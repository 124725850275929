<template>
    <div class="row">
        <div class="col-12">
            <GoodDataTable
                ref="notifications"
                :mode="'local'"
                :no-serial="true"
                :columns="columns"
                :load-local-data="true"
                buttonLabel="Add New Notification"
                @add-new-info="openNewModel" 
            />
        </div>
        <b-modal v-model="newModalState" centered scrollable size="sm" hide-footer no-close-on-backdrop no-close-on-esc
            title="Add New Notification">
            <b-form @submit.prevent="addNotification">
                <div class="row d-flex justify-center">
                    <div class="col-12">
                        <label class="mb-0">Status</label>
                        <b-select :options="eligibleStatuses" v-model="status" required />
                    </div>
                </div>
                <div class="row d-flex justify-content-end mt-3">
                    <div class="col-auto">
                        <b-button variant="primary" type="submit">Save</b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <b-modal v-model="editModalState" size="lg" centered scrollable hide-footer no-close-on-backdrop no-close-on-esc
            title="Set Notification">
            <b-form @submit.prevent="updateNotification">
                <div class="row">
                    <div class="col-12">
                        <label class="mb-0">Notification {{target}}</label>
                        <template v-if="editModalNotificationKey === 'whatsapp'">
                            <div class="row">
                                <div class="col-12 mb-1">
                                    <b-input placeholder="Whatsapp Template Name" :disabled="disableNotification == 1" v-model="whatsAppTemplateName" />
                                </div>
                                <div v-if="disableNotification == 0" class="col-12 mt-1">
                                    <div v-if="!isEmpty(whatsAppVars)" class="row">
                                        <div class="col-12" v-for="(whatsAppVar, tk) in whatsAppVars" :key="`template_${tk}`">
                                            <div class="d-flex justify-content-start mb-2">
                                                <div class="mr-1">
                                                    <b-input disabled :value="`{{ ${tk+1} }}`" />
                                                </div>
                                                <div class="mr-1">
                                                    <b-select placeholder="Select a var" :options="whatsAppVarOpts" v-model="whatsAppVars[tk]" />
                                                </div>
                                                <div class="mr-1">
                                                    <b-button @click="removeWhatsAppVar(tk)" variant="danger">Remove</b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <b-button size="sm" @click="addWhatsAppVar" variant="primary">Add Variable</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="editModalNotificationKey === 'email'">
                            <quill-editor
                                ref="textArea"
                                id="blog-content"
                                v-model="editModalNotificationText"
                                :options="snowOption"
                            />
                        </template>
                        <template v-else>
                            <textarea ref="textArea" class="form-control text-area-22" rows="10" v-model="editModalNotificationText" :required="disableNotification === 0" :disabled="disableNotification === 1"></textarea>
                        </template>
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="disableNotification"
                            name="checkbox-1"
                            :value="1"
                            :unchecked-value="0"
                        >
                            Disable this notification
                        </b-form-checkbox>
                    </div>
                </div>
                <div v-if="disableNotification === 0 && editModalNotificationKey !== 'whatsapp'" class="row mt-3">
                    <div class="col-12">
                        <b-button variant="outline-primary" class="mr-1 mb-1" v-for="(tag, tk) in tags" :key="`tag_${ tk}`" @click="addText(tag.value)" :title="`Add ${tag.title}`">{{ tag.value }}</b-button>
                    </div>
                </div>
                <div class="row d-flex justify-content-end mt-3">
                    <div class="col-auto">
                        <b-button variant="primary" type="submit">Save</b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { quillEditor } from 'vue-quill-editor'

export default {
    name: "NTTargetWise",
    components: {
        GoodDataTable, quillEditor
    },
    props: {
        notificationTemplates: Array,
        target: String,
        statuses: Array,
        tags: Array
    },
    data: () => ({
        newModalState: false,
        editModalState: false,
        editModalNotificationText: '',
        editModalNotificationId: null,
        editModalNotificationKey: null,
        status: null,
        disableNotification: 0,
        snowOption: {
            theme: 'snow',
        },
        whatsAppTemplateName: null,
        whatsAppVars: [],
    }),
    computed: {
        eligibleStatuses: {
            get(){
                const currentStatuses = this.notificationTemplates.map(i => i.status)
                return this.statuses.map(i => ({
                    value: i,
                    text: i,
                    disabled: currentStatuses.some(j => j === i)
                }))
            }
        },
        columns: {
            get(){
                return [
                    {
                        field: 'status',
                        label: 'Status',
                        sortable: false,
                    },
                    {
                        label: 'SMS',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        buttons: [
                            {
                                image: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                class: 'border-danger',
                                canRender: props => !isEmpty(props.sms),
                                action: props => {
                                    this.editNotification(props, 'sms')
                                },
                            },
                            {
                                image: this.$helpers.getIcons('add'),
                                text: 'Add',
                                canRender: props => isEmpty(props.sms),
                                action: props => {
                                    this.editNotification(props, 'sms')
                                },
                            },
                        ],
                    },
                    {
                        label: 'WhatsApp',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        buttons: [
                            {
                                image: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                canRender: props => !isEmpty(props.whatsapp),
                                action: props => {
                                    this.editNotification(props, 'whatsapp')
                                },
                            },
                            {
                                image: this.$helpers.getIcons('add'),
                                text: 'Add',
                                canRender: props => isEmpty(props.whatsapp),
                                action: props => {
                                    this.editNotification(props, 'whatsapp')
                                },
                            },
                        ],
                    },
                    // {
                    //     label: 'Push Notification',
                    //     field: 'action',
                    //     sortable: false,
                    //     type: 'dropdown',
                    //     buttons: [
                    //         {
                    //             image: this.$helpers.getIcons('edit'),
                    //             text: 'Edit',
                    //             action: props => {
                    //                 this.editNotification(props, 'push_notification')
                    //             },
                    //         },
                    //     ],
                    // },
                    {
                        label: 'Email',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        buttons: [
                            {
                                image: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                canRender: props => !isEmpty(props.email),
                                action: props => {
                                    this.editNotification(props, 'email')
                                },
                            },
                            {
                                image: this.$helpers.getIcons('add'),
                                text: 'Add',
                                canRender: props => isEmpty(props.email),
                                action: props => {
                                    this.editNotification(props, 'email')
                                },
                            },
                        ],
                    },
                ]
            }
        },
        whatsAppVarOpts: {
            get(){
                const data = []
                data.push({
                    text: 'Select a variable',
                    value: null,
                    disabled: true
                })
                this.tags.forEach(i => {
                    data.push({
                        text: i.value,
                        value: i.value,
                        disabled: false
                    })
                })
                return data
            }
        }
    },
    methods: {
        isEmpty,
        ...mapActions('notificationTemplate', ['saveNotificationTemplate', 'updateNotificationTemplate']),
        openNewModel() {
            this.status = null
            this.newModalState = true
        },
        editNotification(item, key){
            this.editModalNotificationText = item[key] ?? ''

            if(isEmpty(this.editModalNotificationText)){
                this.disableNotification = 1
            }else{
                this.disableNotification = 0
                if(key === 'whatsapp'){
                    const whatsAppData = JSON.parse(this.editModalNotificationText)
                    this.whatsAppTemplateName = whatsAppData.template
                    this.whatsAppVars = Array.isArray(whatsAppData.vars) ? whatsAppData.vars : []
                }
            }
            

            this.editModalNotificationId = item.id
            this.editModalNotificationKey = key
            this.editModalState = true
        },
        async addNotification() {
            showLoader();
            const payload = {
                target: this.target,
                status: this.status
            }
            this.saveNotificationTemplate(payload).then(r => {
                this.newModalState = false
                this.$emit('newAdded', r)
                hideLoader()
            }).catch(() => {
                hideLoader()
            })
        },
        async updateNotification() {
            if(isEmpty(this.editModalNotificationId)) return false;
            if(isEmpty(this.editModalNotificationKey)) return false;
            showLoader();
            if(this.editModalNotificationKey === 'whatsapp' && this.disableNotification != 1){
                const whatsAppData = {
                    template: this.whatsAppTemplateName,
                    vars: this.whatsAppVars,
                }
                this.editModalNotificationText = JSON.stringify(whatsAppData)
            }
            const payload = {
                id: this.editModalNotificationId,
                [this.editModalNotificationKey]: this.editModalNotificationText
            }
            this.updateNotificationTemplate(payload).then(r => {
                this.editModalState = false
                this.editModalNotificationText = ''
                this.editModalNotificationId = null
                this.editModalNotificationKey = null
                this.$emit('notificationUpdated', r)
                hideLoader()
            }).catch(() => {
                hideLoader()
            })
        },
        addText(insertText) {
            if(this.editModalNotificationKey === 'email'){
                this.editModalNotificationText += insertText
                return
            }
            const textarea = this.$refs.textArea;
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;
            
            const before = this.editModalNotificationText.substring(0, startPos);
            const after = this.editModalNotificationText.substring(endPos, this.editModalNotificationText.length);
            
            this.editModalNotificationText = before + insertText + after;
        
            this.$nextTick(() => {
                const newCursorPosition = startPos + insertText.length;
                textarea.setSelectionRange(newCursorPosition, newCursorPosition);
                textarea.focus();
            });
        },
        removeWhatsAppVar(tk){
            this.whatsAppVars.splice(tk, 1);
        },
        addWhatsAppVar(){
            this.whatsAppVars.push(null)
        },
    },
    mounted(){
        if(this.$refs.notifications){
            this.$refs.notifications.setLocalData(this.notificationTemplates)
        }
    },
    watch: {
        disableNotification(nv){
            if(nv === 1){
                this.editModalNotificationText = '';
            }
        },
        notificationTemplates: {
            handler(nv){
                if(this.$refs.notifications){
                    this.$refs.notifications.setLocalData(nv)
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
<style>
.text-area-22{font-size: 1.2rem !important;}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>