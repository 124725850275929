<template>
  <section class="ecommerce-application">
    <b-card>
      <b-tabs v-model="activeTab">
        <b-tab v-for="(target, tk) in nt_targets" :key="`tab_${tk}`">
          <template #title>
            <b-button v-if="target.toUpperCase() === 'STORE'" variant="outline-dark" class="small_btn" size="sm">
              <b-img height="25" class="cmr-1" :src="$helpers.getIcons('shop')" />
              {{ $t('Store') }}
            </b-button>
            <b-button v-else-if="target.toUpperCase() === 'USER'" variant="outline-dark" class="small_btn" size="sm">
              <b-img height="25" class="cmr-1" :src="$helpers.getIcons('user')" />
              {{ $t('User') }}
            </b-button>
            <b-button v-else-if="target.toUpperCase() === 'AGENT'" variant="outline-dark" class="small_btn" size="sm">
              <b-img height="25" class="cmr-1" :src="$helpers.getIcons('agent')" />
              {{ $t('Agent') }}
            </b-button>
            <b-button v-else-if="target.toUpperCase() === 'PROVIDER'" variant="outline-dark" class="small_btn" size="sm">
              <b-img height="25" class="cmr-1" :src="$helpers.getIcons('provider')" />
              {{ $t('Provider') }}
            </b-button>
          </template>
          <NTTargetWise :notificationTemplates="notificationTemplates" :tags="tags" :target="tk" :statuses="nt_statuses"
            @newAdded="newAdded" @notificationUpdated="notificationUpdated" />
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
import NTTargetWise from '@/views/notification-template/NTTargetWise.vue';
export default {
  name: "NotificationTemplateSettings",
  components: {
    NTTargetWise
  },
  data: () => ({
    nt_targets: [],
    nt_statuses: [],
    nts: [],
    tags: [],
    activeTab: 0,
  }),
  computed: {
    target: {
      get() {
        const targetKeys = Object.keys(this.nt_targets);
        return targetKeys[this.activeTab]
      }
    },
    notificationTemplates: {
      get() {
        return this.nts.filter(i => i.target === this.target).sort((b, a) => new Date(b.created_at) - new Date(a.created_at));
      }
    }
  },
  methods: {
    ...mapActions('notificationTemplate', ['getNotificationTemplates']),
    isEmpty,
    getData() {
      showLoader()
      this.getNotificationTemplates().then(r => {
        this.tags = r.tags
        this.nts = r.nts
        this.nt_statuses = r.nt_statuses
        this.nt_targets = r.nt_targets
        hideLoader();
      }).catch(e => {
        hideLoader();
      })
    },
    newAdded(nt) {
      this.nts.push(nt)
    },
    notificationUpdated(nt) {
      this.nts = this.nts.map(i => i.id == nt.id ? nt : i)
    },
  },
  mounted() {
    this.getData()
  }

}
</script>
<style>
.custom-disabled:disabled {
  color: #000 !important;
}
</style>